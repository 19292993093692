import { Button, Checkbox, Col, Divider, Form, Result, Row, Spin, notification } from "antd";
import { useForm } from "antd/es/form/Form";
import { FunctionComponent, useEffect, useState } from "react";
import { Adhesion } from "../../services/adhesion";
import { useLocation, useNavigate } from "react-router-dom";
import { APPLICATION_DATE_FORMAT, APPLICATION_DATE_TIME_FORMAT, getConsentementAdhesionLibelle, validateCodePostal, validateEmail, validateMajorite, validateMontantMinAdhesion, validatePhoneNumber } from "../../utils/FormUtils";
import { DefaultOptionType } from "antd/es/select";
import useApi from "../../hooks/useApi";
import { ADHESION_ENDPOINT, TARIFS_ENDPOINT } from "../../services/services";
import { TarifDto } from "../../services/tarif";
import { StatutInscription } from "../../services/inscription";
import { InputNumberFormItem } from "../common/InputNumberFormItem";
import { SelectFormItem } from "../common/SelectFormItem";
import { InputFormItem } from "../common/InputFormItem";
import { DatePickerFormItem } from "../common/DatePickerFormItem";
import { RadioGroupFormItem } from "../common/RadioGroupFormItem";
import dayjs from "dayjs";


export const AdhesionForm: FunctionComponent = () => {

    const navigate = useNavigate();
    const [form] = useForm();
    const location = useLocation();
    const id = location.state ? location.state.id : undefined;
    const isReadOnly = location.state ? location.state.isReadOnly : undefined;
    const isAdmin = location.state ? location.state.isAdmin : undefined;
    const [versementMensuelOptions, setVersementMensuelOptions] = useState<DefaultOptionType[]>();
    const { result, apiCallDefinition, setApiCallDefinition, resetApi, isLoading } = useApi();
    const { result: resultTarifs } = useApi({ method: "GET", url: TARIFS_ENDPOINT, params: { application: "ADHESION" } });
    const [autreMontantVisible, setAutreMontantVisible] = useState<boolean>(false);
    const [inscriptionSuccess, setInscriptionSuccess] = useState<boolean>(false);
    const [consentementChecked, setConsentementChecked] = useState(false);

    const onFinish = async (adhesion: Adhesion) => {
        if (!isAdmin && !consentementChecked) {
            notification.open({ message: "Veuillez donner votre consentement à la collecte et au traitement de vos données avant de valider", type: "warning" });
            return;
        }
        if (adhesion.dateInscription) {
            adhesion.dateInscription = dayjs(adhesion.dateInscription).format(APPLICATION_DATE_TIME_FORMAT);
        }
        adhesion.dateNaissance = dayjs(adhesion.dateNaissance).format(APPLICATION_DATE_FORMAT);
        setApiCallDefinition({ method: "POST", url: ADHESION_ENDPOINT, data: adhesion });
    };

    const getCiviliteOptions = () => {
        return [{ value: "M", label: "Monsieur" }, { value: "MME", label: "Madame" }];
    }

    const onMontantChanged = (value: string, option: any) => {
        setAutreMontantVisible(option.label === "Autre");
    }

    const formatMontant = (montant: number) => {
        return montant + " €";
    }

    useEffect(() => {
        if (resultTarifs) {
            const resultAsTarifs = resultTarifs as TarifDto[];
            const tarifOptions: DefaultOptionType[] = [];
            resultAsTarifs.forEach(tarif => tarifOptions.push({ value: tarif.id, label: tarif.type === "FIXE" ? formatMontant(tarif.montant) : "Autre" }));
            setVersementMensuelOptions(tarifOptions);
        }
    }, [resultTarifs]);


    useEffect(() => {
        if (apiCallDefinition?.url === ADHESION_ENDPOINT && apiCallDefinition.method === "POST" && result) {
            if (isAdmin) {
                notification.open({ message: "Les modifications ont bien été enregistrées", type: "success" });
                navigate("/adminAdhesion");
            } else {
                setInscriptionSuccess(true);
                form.resetFields();
            }
            resetApi();
        }
        if (apiCallDefinition?.method === "GET" && result) { // load de l'adhésion
            const adhesion = result as Adhesion;
            adhesion.dateInscription = dayjs(adhesion.dateInscription, APPLICATION_DATE_TIME_FORMAT);
            adhesion.dateNaissance = dayjs(adhesion.dateNaissance, APPLICATION_DATE_FORMAT);
            if (adhesion.montantAutre) {
                setAutreMontantVisible(true);
            }
            form.setFieldsValue(adhesion);
            resetApi();
        }
    }, [result]);

    useEffect(() => {
        if (id) {
            setApiCallDefinition({ method: "GET", url: ADHESION_ENDPOINT + "/" + id });
        }
    }, []);

    return inscriptionSuccess ? (
        <Result
            status="success"
            title="Adhésion enregistrée"
            subTitle={(<div className="result-message">Votre adhésion a bien été enregistrée. Vous serez recontacté rapidement.</div>)}
            extra={[
                <Button type="primary" onClick={() => setInscriptionSuccess(false)}>
                    Nouvelle adhésion
                </Button>]}
        />) :
        (
            <Form
                name="adhesion"
                onFinish={onFinish}
                autoComplete="off"
                form={form}
                className="container-form"
            >
                <Spin spinning={isLoading} size="large" tip="Enregistrement de votre adhésion...">
                    <InputFormItem name="id" formStyle={{ display: "none" }} type="hidden" />
                    <InputFormItem name="signature" formStyle={{ display: "none" }} type="hidden" />
                    <InputFormItem name="dateInscription" formStyle={{ display: "none" }} type="hidden" />
                    <Row>
                        <Col span={24}>
                            <Divider orientation="left">Identité</Divider>
                        </Col>
                    </Row>
                    <Row gutter={[0, 32]}>
                        <Col span={6}>
                            <SelectFormItem name="titre" label="Titre" rules={[{ required: true, message: "Veuillez saisir votre titre" }]}
                                disabled={isReadOnly} options={getCiviliteOptions()} />
                        </Col>
                    </Row>
                    <Row gutter={[16, 32]}>
                        <Col span={12}>
                            <InputFormItem label="Nom" name="nom" rules={[{ required: true, message: "Veuillez saisir votre nom" }]}
                                disabled={isReadOnly} />
                        </Col>
                        <Col span={12}>
                            <InputFormItem disabled={isReadOnly} label="Prénom" name="prenom" rules={[{ required: true, message: "Veuillez saisir votre prénom" }]} />
                        </Col>
                    </Row>
                    <Row gutter={[16, 32]}>
                        <Col span={24}>
                            <DatePickerFormItem label="Date de naissance" name="dateNaissance" rules={[{ required: true, message: "Veuillez saisir votre date de naissance" },
                            { validator: validateMajorite }
                            ]}
                                placeholder="Sélectionnez une date de naissance" disabled={isReadOnly} />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Divider orientation="left">Contacts</Divider>
                        </Col>
                    </Row>
                    <Row gutter={[16, 32]}>
                        <Col span={12}>
                            <InputFormItem label="Numéro et rue" name="numeroEtRue" rules={[{ required: true, message: "Veuillez saisir votre numéro et rue" }]}
                                disabled={isReadOnly} />
                        </Col>
                    </Row>
                    <Row gutter={[16, 32]}>
                        <Col span={12}>
                            <InputFormItem label="Code postal" name={"codePostal"} rules={[{ validator: validateCodePostal }]} disabled={isReadOnly} required />
                        </Col>
                        <Col span={12}>
                            <InputFormItem label="Ville" name="ville" rules={[{ required: true, message: "Veuillez saisir votre ville" }]}
                                disabled={isReadOnly} />
                        </Col>
                    </Row>
                    <Row gutter={[16, 32]}>
                        <Col span={12}>
                            <InputFormItem label="Tél. mobile" name="mobile" required
                                rules={[{ validator: validatePhoneNumber }]} disabled={isReadOnly} />
                        </Col>
                    </Row>
                    <Row gutter={[16, 32]}>
                        <Col span={12}>
                            <InputFormItem label="E-mail" name="email" rules={[{ validator: validateEmail }]} disabled={isReadOnly} required />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Divider orientation="left">Versement mensuel</Divider>
                        </Col>
                    </Row>
                    <Row gutter={[16, 32]}>
                        <Col span={12}>
                            <SelectFormItem name="idTarif" label="Je m'engage à verser mensuellement" rules={[{
                                required: true, message: "Veuillez saisir votre versement mensuel"
                            }]}
                                disabled={isReadOnly} options={versementMensuelOptions} onChange={onMontantChanged} />
                        </Col>
                        {autreMontantVisible && (<Col span={12}>
                            <InputNumberFormItem name="montantAutre" label="Montant" disabled={isReadOnly} addonAfter="€"
                                rules={[{ validator: validateMontantMinAdhesion }, { required: true, message: "Veuillez saisir le montant de votre cotisation" }]} min={1} />
                        </Col>)
                        }
                    </Row>
                    {isAdmin && (<><Divider orientation="left">Administration</Divider>
                        <Row gutter={[16, 32]}>
                            <Col span={12}>
                                <RadioGroupFormItem label="Statut adhésion" name="statut" disabled={isReadOnly} radioOptions={[{ value: StatutInscription.PROVISOIRE, label: "Provisoire" },
                                { value: StatutInscription.VALIDEE, label: "Validée" }]} />
                            </Col>
                        </Row>
                        <Row gutter={[16, 32]}>
                            <Col span={12}>
                                <InputFormItem label="Numéro de membre" name="noMembre" disabled={isReadOnly} />
                            </Col>
                        </Row>
                    </>)}
                    <Row gutter={[16, 32]}>
                        <Col span={24}>
                            {!isAdmin && (
                                <Checkbox checked={consentementChecked} onChange={(e) => { setConsentementChecked(e.target.checked) }}>
                                    {getConsentementAdhesionLibelle()}
                                </Checkbox>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        {isAdmin && !isReadOnly && (<Button style={{ marginTop: 30 }} type="primary" htmlType="submit">Enregistrer</Button>)}
                        {!isAdmin && (<Button style={{ marginTop: 30 }} type="primary" htmlType="submit">Valider mon adhésion</Button>)}
                    </Row>
                </Spin>
            </Form>
        );
}